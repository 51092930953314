<template>
  <div class="readme-article">
    <h2 id="产品">3.产品</h2>
    <h3 id="产品列表">3.1产品列表</h3>
    <p>
      查看已上传的的产品列表，包含产品图片、名称、分类、状态、添加时间等信息，支持新增、编辑、删除及一键复制功能。
    </p>
    <p>
      <img src="@/assets/img/card/3-1.png" />
    </p>
    <h3 id="产品分类">3.2产品分类</h3>
    <p>
      查看已上传的的产品分类，包含分类名称、排序、添加时间时间等信息，支持新增、编辑、删除。
    </p>
    <p>
      <img src="@/assets/img/card/3-2.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Card3-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>